<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ "assignments.selectUsers" | translate }}</p>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="p-t-md">
        <cipo-select [control]="listControl" />
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()" [disabled]="saving()">{{ "general.close" | translate }}</button>
    <button mat-raised-button color="primary" (click)="returnUsers()" [disabled]="saving()">{{ "general.save" | translate }}</button>
</mat-dialog-actions>
